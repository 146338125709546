@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-Bold-webfont.eot');
    src: url('assets/fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('assets/fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('assets/fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('assets/fonts/OpenSans-Regular-webfont.eot');
    src: url('assets/fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('assets/fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('assets/fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('assets/fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}