/*!
Theme Name: laserpro
Author: Koos van Egmond (Inventeers)
Author URI: https://inventeers.nl
Description: LaserproGames Wordpress theme
Version: 1.0
*/

// NOTE ABOUT PREFIXES
// like, -webkit-gradiant for example.. those are automatically added
// by 'gulp-autoprefixer' so you won't find any prefixes in here. Don't worry.
// it'll be all fine ;)

// Import external scss files
@import 'vars';
@import 'mixins';
@import 'fonts';

// Import fonts
//@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

// =====================
// Styling and overrides
// =====================
html, body {
  width: 100%;
  height: 100%;
  display: inline-table;
  background-attachment: fixed !important;
	/*background-image: url('assets/image/bg_stripes.png');*/
	background-repeat: repeat-x;
	background-position: center top;
}

body {
  font-family: $font-default-family;
  font-size: $font-default-size;
}

a {
  text-decoration: underline;
  color: $text-link;
}

// Default header image
/*#header-container {*/
	/*background: transparent url('assets/image/bg_header_green.png') no-repeat center bottom;*/
/*}*/


#header-container {
    margin-bottom:10px;

  header#header {
    height: 194px;
    position: relative;

    h1 {
      margin-top: 50px;
      display: inline-block;
    }

    .slogan {
      margin-left: 20px;
    }

    nav {
      display: inline-block;
      position: absolute;
      top: 1.5em;
      right: 2em;

      ul {
        display: inline;
        list-style: none;
        float: right;
        margin: 0;
        padding: 0;

        li {
          display: inline;
          margin-left: 0.1em;
          padding-left: 0.2em;

          &:not(:first-child):before {
            content: "|";
          }

          &:before, a {
            color: $text-light;
            text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
            text-decoration: none;
          }

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

#main-nav-container {
  /*border-radius: 0 !important;*/
  /*border: none;*/
  /*box-shadow: inset 0 -1px 0 $border-detail;*/
  /*@include gradient($bg-sidebar-light, $bg-sidebar-dark, 100%);*/
	background: transparent none !important;
	border: none !important;
	box-shadow: none !important;
  min-height:32px;
  margin-bottom: 5px;

	.navbar-cont {
		@include gradient(#fff, #bebebe, 100%);
	}

  div.navbar-collapse {
    text-align: center;
    height: 32px !important;
		padding-left: 0; padding-right: 0;

    ul {
      text-align: center;
      display: inline-block;
      float: none;

      li {
        // float: none !important;
        // display: inline-block;

        &.active a,
        a:hover {
          background: transparent none;
          box-shadow: none;
          /*border-bottom: 2px solid #fff;*/
          margin-bottom: 0;
        }

        a {
          text-transform: uppercase;
          font-weight: bold;
          color: $text-regular;
          margin: 0 .1em;
          margin-bottom: 2px;
          text-decoration: none;
					padding: 7px 12px;
        }
      }
    }
  }
}

#content {
  background-color: $bg-content-block;
  padding: 2em;
	border-radius: 5px;

  h1 {
    color: $text-light;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 1em 0;
    padding: .5em 0;
    text-align: center;
    border-bottom: 1px solid $border-detail;
  }

  h2 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
  }

	h3 {
		font-size: 14px;
		font-weight: normal;
		margin-bottom: 0;
		text-transform: uppercase;
	}

  article:not(:first-child) {
    margin-top: 3em;
  }

	/*.video {*/
		/*height: auto;*/
	/*}*/

	/*video, .video-js {*/
		/*width: 100% !important;*/
	/*}*/

	.video-js, .vjs-tech {
		position: relative !important;
		width: 100% !important;
		height: auto !important;
	}

	section.subpages {
		display: block;
		float: left;
		width: 100%;
		clear: both;

		a.subbar {
			width: 100%;
			margin-bottom: 0.7em !important;
			background-color: #fff;
		}
	}
}

article.newsitem {
	header {
		background-color: #ddd;
		padding: 0.3em;
		width: 100%;
		border-radius: 4px;
		margin-bottom: 0.65em;

		h2 {
			margin: 0;
		}
	}
}

article.videoitem {
	margin-top: 0 !important;
	margin-bottom: 0.5em;
}

aside#sidebar a, a.subbar {
	text-decoration: none !important;
	display: inline;
	position: relative;
	float: left;
	// overflow: hidden;
	margin-bottom: 1.2em;
	@include transit(0.2s);

	article, .card {
		@include gradient($bg-sidebar-light, $bg-sidebar-dark, 100%);
		padding: 0.7em 1em;
		display: flex;
		margin: 0;
		position: relative;
		border-bottom: 1px solid $border-detail;
		/*box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);*/
		overflow: hidden;
		color: $text-regular;
		border-radius: 4px;

		img {
			margin-right: 1em;
			width: 100px !important;
			height: 100px !important;
			border-radius: 2px;
		}

		div {
			h1, p {
				color: $text-regular !important;
			}

			h1 {
				font-size: $font-default-size !important;
				font-weight: bold;
				margin-top: 0;
				margin-bottom: 10px !important;
				padding: 0 !important;
				background: none !important;
				border: none !important;
				text-align: left !important;
			}

			p {
				display: inline;
			}
		}
	}

	&:hover /*article*/ {
		// box-shadow: 0px 0px 15px rgba(255, 255, 255, 1)
		/*box-shadow: 0px 0px 15px rgba(0,0,0,0.7);*/
		transform: translateY(-3px) scale(1.02, 1.02);

		.card {
			@include gradient(#dadada, #b7b7b7, 100%);
		}
	}

	&.child {
		margin-left: 13%;
		width: 87%;
	}
}

aside#sidebar nav a div {
	font-size: 14px;
	font-weight: bold;
}

// Overrule image dimensions in content sub pages
a.subbar > article > img {
	width: 85px !important;
	height: 85px !important;
}

footer#footer, .footer {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5em 0;
  margin: 1em 0 1em 0;

  p, a {
    color: $text-light;
    margin: 0;
    padding: 0;
    text-decoration: none;
    white-space: pre-line;
  }

  p {
    margin: 0 1em;
  }

	ul {
		display: block;
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			display: inline;
			margin-left: 0.1em;
			padding-left: 0.2em;

			&:not(:first-child):before {
				content: "|";
			}

			&:before, a {
				color: $text-light;
				font-size: 14px;
				font-weight: bold;
				text-decoration: none;
			}

			a:hover {
				text-decoration: underline;
			}
		}
	}
}

// =======
// Globals
// =======
@each $parent-node in (h1, h2, h3, a) {
  #{$parent-node} > span:not(.glyphicon) {
    display: none;
    visibility: hidden;
  }
}

.ink {
  display: block; position: absolute;
  // background: hsl(140, 40%, 80%);
  border-radius: 100%;
  transform: scale(0);
  visibility: visible !important;
  z-index: 100;
}

.ink.animate { animation: ripple 0.65s linear; }
@keyframes ripple {
  100% { opacity:0; transform: scale(2.5); }
}

.photoalbum {
	/*display: flex;*/

	.photo {
		/*display: flex;*/
		/*flex: 1;*/
		margin-bottom: 2em;

		img {
			width: 100%;
			/*height: auto;*/
			height: 120px;
			/*flex: 1;*/
		}
	}
}

// Disabled for now.
/*.wp-caption {
  overflow: hidden;

  &, & img {
    display: inline-block;
    width: 100% !important;
    height: auto !important;
    position: relative;

    .wp-caption-text {
      display: block; position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      color: $text-light;
      background-color: rgba(#000, 0.6);
      margin: 0;
      padding: 0.5em 0;
      line-height: 1.5;
      font-size: 14px;

      transition: all 0.3s ease-in-out;
    }

    .wp-caption-title {
      display: block;
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      clear: both;
      // line-height: 1.8;
    }

    &.hover .wp-caption-text {
      transform: translateY(150%);
      opacity: 0;
    }
  }
}*/

// Slider (Bootstrap Carousel)
.carousel img {
	min-width: 100%;
	height: 400px !important;
}

.carousel-control {
	opacity: 0 !important;
	@include transit(0.2s);
}

.carousel.hover .carousel-control {
	opacity: 1 !important;
}

.carousel-caption {
	background-color: rgba(0, 0, 0, 0.7);
	width: 100%;
	left: 0;
	margin: 0;
	padding: 0;
	bottom: 0;
	line-height: 1.5;
	font-size: 14px;
	text-shadow: none;

	h3 {
		font-family: $font-default-family !important;
		margin: 0.6em 0 .3em;
		padding: 0;
		font-size: 18px;
		font-weight: bold;
	}
}

// ===================
// Background variants
// ===================

@each $variant, $light, $dark in $bg-variants {
  body.bg-variant-#{$variant} {
    @include gradient($light, $dark, 35%, true);

    #header-container {
			background: transparent url('assets/image/bg_header_#{$variant}.png') no-repeat center bottom;
    }

    #main-nav-container ul li {
      &.active a,
      a:hover {
        /*color: $light;*/
        /*border-color: $light;*/
				color: #fff;
				background-color: $light;
				padding: 2px 12px;
				margin-top: 5px;
				border-radius: 3px;
      }
    }

    #content h1 {
      @include gradient(lighten($dark, 15%), lighten($light, 0%), 60%);
			border-radius: 4px;
    }

    footer#footer, .footer {
      // @include gradient(lighten($dark, 15%), lighten($light, 0%), 60%);
      background-color: $light;
			border-radius: 4px;
    }

    .ink {
      background-color: rgba(lighten($light, 40%), 0.5);
      // background-color: rgba(#ddd, 0.6);
    }

		aside#sidebar a {
			.card.active {
				@include gradient(lighten($dark, 15%), lighten($light, 0%), 60%);
				border-color: lighten($light, 0%);
				color: $text-light;
			}
		}
  }
}

// ===========================
// Device type specific tweaks
// ===========================
// Phone & tablet sizes
@media screen and (max-width: 768px) {
  // Smaller header for mobile devices
  #header-container {
    header#header {
      height: 92px;

      h1 {
        margin-top: 20px;
      }

      nav {
        display: none;
        visibility: hidden;
      }
    }
  }

  // aside#sidebar {
  //   display: none;
  //   visibility: hidden;
  // }
}

// Tablet size
@media screen and (min-width: 991px) {
  aside#sidebar a {
    /*padding: 0;*/
  }
}

@media screen and (max-width: 991px) {
  header#header .slogan {
    width: 455px;
    height: 25px;
    margin: 0 !important;
  }

  #main-nav-container div.navbar-collapse {
    height: auto !important;
  }

  // Start navigation collapse
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin: 7.5px -15px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block!important;
  }
  .collapsing {
    overflow: hidden!important;
  }
  // End navigation collapse
}

// Desktop size
@media screen and (max-width: 1200px) {
  #main-nav-container li a {
    margin-left: 0.1em !important;
    margin-right: 0.1em !important;
  }
}
