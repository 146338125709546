/*!
Theme Name: laserpro
Author: Koos van Egmond (Inventeers)
Author URI: https://inventeers.nl
Description: LaserproGames Wordpress theme
Version: 1.0
*/
@font-face {
  font-family: 'Open Sans';
  src: url("assets/fonts/OpenSans-Bold-webfont.eot");
  src: url("assets/fonts/OpenSans-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/OpenSans-Bold-webfont.woff") format("woff"), url("assets/fonts/OpenSans-Bold-webfont.ttf") format("truetype"), url("assets/fonts/OpenSans-Bold-webfont.svg#open_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url("assets/fonts/OpenSans-Regular-webfont.eot");
  src: url("assets/fonts/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("assets/fonts/OpenSans-Regular-webfont.woff") format("woff"), url("assets/fonts/OpenSans-Regular-webfont.ttf") format("truetype"), url("assets/fonts/OpenSans-Regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

html, body {
  width: 100%;
  height: 100%;
  display: inline-table;
  background-attachment: fixed !important;
  /*background-image: url('assets/image/bg_stripes.png');*/
  background-repeat: repeat-x;
  background-position: center top;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
}

a {
  text-decoration: underline;
  color: #6e9bbd;
}

/*#header-container {*/
/*background: transparent url('assets/image/bg_header_green.png') no-repeat center bottom;*/
/*}*/
#header-container {
  margin-bottom: 10px;
}

#header-container header#header {
  height: 194px;
  position: relative;
}

#header-container header#header h1 {
  margin-top: 50px;
  display: inline-block;
}

#header-container header#header .slogan {
  margin-left: 20px;
}

#header-container header#header nav {
  display: inline-block;
  position: absolute;
  top: 1.5em;
  right: 2em;
}

#header-container header#header nav ul {
  display: inline;
  list-style: none;
  float: right;
  margin: 0;
  padding: 0;
}

#header-container header#header nav ul li {
  display: inline;
  margin-left: 0.1em;
  padding-left: 0.2em;
}

#header-container header#header nav ul li:not(:first-child):before {
  content: "|";
}

#header-container header#header nav ul li:before, #header-container header#header nav ul li a {
  color: #ffffff;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

#header-container header#header nav ul li a:hover {
  text-decoration: underline;
}

#main-nav-container {
  /*border-radius: 0 !important;*/
  /*border: none;*/
  /*box-shadow: inset 0 -1px 0 $border-detail;*/
  /*@include gradient($bg-sidebar-light, $bg-sidebar-dark, 100%);*/
  background: transparent none !important;
  border: none !important;
  box-shadow: none !important;
  min-height: 32px;
  margin-bottom: 5px;
}

#main-nav-container .navbar-cont {
  background-color: #fff;
  background: linear-gradient(#fff 0%, #bebebe 100%);
}

#main-nav-container div.navbar-collapse {
  text-align: center;
  height: 32px !important;
  padding-left: 0;
  padding-right: 0;
}

#main-nav-container div.navbar-collapse ul {
  text-align: center;
  display: inline-block;
  float: none;
}

#main-nav-container div.navbar-collapse ul li.active a,
#main-nav-container div.navbar-collapse ul li a:hover {
  background: transparent none;
  box-shadow: none;
  /*border-bottom: 2px solid #fff;*/
  margin-bottom: 0;
}

#main-nav-container div.navbar-collapse ul li a {
  text-transform: uppercase;
  font-weight: bold;
  color: #2a2a2a;
  margin: 0 .1em;
  margin-bottom: 2px;
  text-decoration: none;
  padding: 7px 12px;
}

#content {
  background-color: #eeeeee;
  padding: 2em;
  border-radius: 5px;
  /*.video {*/
  /*height: auto;*/
  /*}*/
  /*video, .video-js {*/
  /*width: 100% !important;*/
  /*}*/
}

#content h1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  margin: 0 0 1em 0;
  padding: .5em 0;
  text-align: center;
  border-bottom: 1px solid #c0c0c0;
}

#content h2 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
}

#content h3 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 0;
  text-transform: uppercase;
}

#content article:not(:first-child) {
  margin-top: 3em;
}

#content .video-js, #content .vjs-tech {
  position: relative !important;
  width: 100% !important;
  height: auto !important;
}

#content section.subpages {
  display: block;
  float: left;
  width: 100%;
  clear: both;
}

#content section.subpages a.subbar {
  width: 100%;
  margin-bottom: 0.7em !important;
  background-color: #fff;
}

article.newsitem header {
  background-color: #ddd;
  padding: 0.3em;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 0.65em;
}

article.newsitem header h2 {
  margin: 0;
}

article.videoitem {
  margin-top: 0 !important;
  margin-bottom: 0.5em;
}

aside#sidebar a, a.subbar {
  text-decoration: none !important;
  display: inline;
  position: relative;
  float: left;
  margin-bottom: 1.2em;
  transition: all 0.2s ease-out;
}

aside#sidebar a article, aside#sidebar a .card, a.subbar article, a.subbar .card {
  background-color: #ffffff;
  background: linear-gradient(#ffffff 0%, #f0f0f0 100%);
  padding: 0.7em 1em;
  display: flex;
  margin: 0;
  position: relative;
  border-bottom: 1px solid #c0c0c0;
  /*box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);*/
  overflow: hidden;
  color: #2a2a2a;
  border-radius: 4px;
}

aside#sidebar a article img, aside#sidebar a .card img, a.subbar article img, a.subbar .card img {
  margin-right: 1em;
  width: 100px !important;
  height: 100px !important;
  border-radius: 2px;
}

aside#sidebar a article div h1, aside#sidebar a article div p, aside#sidebar a .card div h1, aside#sidebar a .card div p, a.subbar article div h1, a.subbar article div p, a.subbar .card div h1, a.subbar .card div p {
  color: #2a2a2a !important;
}

aside#sidebar a article div h1, aside#sidebar a .card div h1, a.subbar article div h1, a.subbar .card div h1 {
  font-size: 13px !important;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 10px !important;
  padding: 0 !important;
  background: none !important;
  border: none !important;
  text-align: left !important;
}

aside#sidebar a article div p, aside#sidebar a .card div p, a.subbar article div p, a.subbar .card div p {
  display: inline;
}

aside#sidebar a:hover, a.subbar:hover {
  /*box-shadow: 0px 0px 15px rgba(0,0,0,0.7);*/
  transform: translateY(-3px) scale(1.02, 1.02);
}

aside#sidebar a:hover .card, a.subbar:hover .card {
  background-color: #dadada;
  background: linear-gradient(#dadada 0%, #b7b7b7 100%);
}

aside#sidebar a.child, a.subbar.child {
  margin-left: 13%;
  width: 87%;
}

aside#sidebar nav a div {
  font-size: 14px;
  font-weight: bold;
}

a.subbar > article > img {
  width: 85px !important;
  height: 85px !important;
}

footer#footer, .footer {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  padding: 0.5em 0;
  margin: 1em 0 1em 0;
}

footer#footer p, footer#footer a, .footer p, .footer a {
  color: #ffffff;
  margin: 0;
  padding: 0;
  text-decoration: none;
  white-space: pre-line;
}

footer#footer p, .footer p {
  margin: 0 1em;
}

footer#footer ul, .footer ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

footer#footer ul li, .footer ul li {
  display: inline;
  margin-left: 0.1em;
  padding-left: 0.2em;
}

footer#footer ul li:not(:first-child):before, .footer ul li:not(:first-child):before {
  content: "|";
}

footer#footer ul li:before, footer#footer ul li a, .footer ul li:before, .footer ul li a {
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

footer#footer ul li a:hover, .footer ul li a:hover {
  text-decoration: underline;
}

h1 > span:not(.glyphicon) {
  display: none;
  visibility: hidden;
}

h2 > span:not(.glyphicon) {
  display: none;
  visibility: hidden;
}

h3 > span:not(.glyphicon) {
  display: none;
  visibility: hidden;
}

a > span:not(.glyphicon) {
  display: none;
  visibility: hidden;
}

.ink {
  display: block;
  position: absolute;
  border-radius: 100%;
  transform: scale(0);
  visibility: visible !important;
  z-index: 100;
}

.ink.animate {
  animation: ripple 0.65s linear;
}

@keyframes ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

.photoalbum {
  /*display: flex;*/
}

.photoalbum .photo {
  /*display: flex;*/
  /*flex: 1;*/
  margin-bottom: 2em;
}

.photoalbum .photo img {
  width: 100%;
  /*height: auto;*/
  height: 120px;
  /*flex: 1;*/
}

/*.wp-caption {
  overflow: hidden;

  &, & img {
    display: inline-block;
    width: 100% !important;
    height: auto !important;
    position: relative;

    .wp-caption-text {
      display: block; position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      color: $text-light;
      background-color: rgba(#000, 0.6);
      margin: 0;
      padding: 0.5em 0;
      line-height: 1.5;
      font-size: 14px;

      transition: all 0.3s ease-in-out;
    }

    .wp-caption-title {
      display: block;
      margin: 0;
      font-size: 18px;
      font-weight: bold;
      clear: both;
      // line-height: 1.8;
    }

    &.hover .wp-caption-text {
      transform: translateY(150%);
      opacity: 0;
    }
  }
}*/
.carousel img {
  min-width: 100%;
  height: 400px !important;
}

.carousel-control {
  opacity: 0 !important;
  transition: all 0.2s ease-out;
}

.carousel.hover .carousel-control {
  opacity: 1 !important;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  left: 0;
  margin: 0;
  padding: 0;
  bottom: 0;
  line-height: 1.5;
  font-size: 14px;
  text-shadow: none;
}

.carousel-caption h3 {
  font-family: "Open Sans", sans-serif !important;
  margin: 0.6em 0 .3em;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
}

body.bg-variant-green {
  background-color: #04a734;
  background-image: url("assets/image/bg_stripes.png");
  background: url("assets/image/bg_stripes.png"), linear-gradient(#04a734 0%, #0c2d01 35%);
}

body.bg-variant-green #header-container {
  background: transparent url("assets/image/bg_header_green.png") no-repeat center bottom;
}

body.bg-variant-green #main-nav-container ul li.active a,
body.bg-variant-green #main-nav-container ul li a:hover {
  /*color: $light;*/
  /*border-color: $light;*/
  color: #fff;
  background-color: #04a734;
  padding: 2px 12px;
  margin-top: 5px;
  border-radius: 3px;
}

body.bg-variant-green #content h1 {
  background-color: #207803;
  background: linear-gradient(#207803 0%, #04a734 60%);
  border-radius: 4px;
}

body.bg-variant-green footer#footer, body.bg-variant-green .footer {
  background-color: #04a734;
  border-radius: 4px;
}

body.bg-variant-green .ink {
  background-color: rgba(123, 252, 161, 0.5);
}

body.bg-variant-green aside#sidebar a .card.active {
  background-color: #207803;
  background: linear-gradient(#207803 0%, #04a734 60%);
  border-color: #04a734;
  color: #ffffff;
}

body.bg-variant-red {
  background-color: #b31e25;
  background-image: url("assets/image/bg_stripes.png");
  background: url("assets/image/bg_stripes.png"), linear-gradient(#b31e25 0%, #2b0002 35%);
}

body.bg-variant-red #header-container {
  background: transparent url("assets/image/bg_header_red.png") no-repeat center bottom;
}

body.bg-variant-red #main-nav-container ul li.active a,
body.bg-variant-red #main-nav-container ul li a:hover {
  /*color: $light;*/
  /*border-color: $light;*/
  color: #fff;
  background-color: #b31e25;
  padding: 2px 12px;
  margin-top: 5px;
  border-radius: 3px;
}

body.bg-variant-red #content h1 {
  background-color: #770006;
  background: linear-gradient(#770006 0%, #b31e25 60%);
  border-radius: 4px;
}

body.bg-variant-red footer#footer, body.bg-variant-red .footer {
  background-color: #b31e25;
  border-radius: 4px;
}

body.bg-variant-red .ink {
  background-color: rgba(241, 172, 175, 0.5);
}

body.bg-variant-red aside#sidebar a .card.active {
  background-color: #770006;
  background: linear-gradient(#770006 0%, #b31e25 60%);
  border-color: #b31e25;
  color: #ffffff;
}

body.bg-variant-purple {
  background-color: #cb00c7;
  background-image: url("assets/image/bg_stripes.png");
  background: url("assets/image/bg_stripes.png"), linear-gradient(#cb00c7 0%, #30003a 35%);
}

body.bg-variant-purple #header-container {
  background: transparent url("assets/image/bg_header_purple.png") no-repeat center bottom;
}

body.bg-variant-purple #main-nav-container ul li.active a,
body.bg-variant-purple #main-nav-container ul li a:hover {
  /*color: $light;*/
  /*border-color: $light;*/
  color: #fff;
  background-color: #cb00c7;
  padding: 2px 12px;
  margin-top: 5px;
  border-radius: 3px;
}

body.bg-variant-purple #content h1 {
  background-color: #6f0087;
  background: linear-gradient(#6f0087 0%, #cb00c7 60%);
  border-radius: 4px;
}

body.bg-variant-purple footer#footer, body.bg-variant-purple .footer {
  background-color: #cb00c7;
  border-radius: 4px;
}

body.bg-variant-purple .ink {
  background-color: rgba(255, 152, 253, 0.5);
}

body.bg-variant-purple aside#sidebar a .card.active {
  background-color: #6f0087;
  background: linear-gradient(#6f0087 0%, #cb00c7 60%);
  border-color: #cb00c7;
  color: #ffffff;
}

body.bg-variant-turquoise {
  background-color: #60bfb5;
  background-image: url("assets/image/bg_stripes.png");
  background: url("assets/image/bg_stripes.png"), linear-gradient(#60bfb5 0%, #12242f 35%);
}

body.bg-variant-turquoise #header-container {
  background: transparent url("assets/image/bg_header_turquoise.png") no-repeat center bottom;
}

body.bg-variant-turquoise #main-nav-container ul li.active a,
body.bg-variant-turquoise #main-nav-container ul li a:hover {
  /*color: $light;*/
  /*border-color: $light;*/
  color: #fff;
  background-color: #60bfb5;
  padding: 2px 12px;
  margin-top: 5px;
  border-radius: 3px;
}

body.bg-variant-turquoise #content h1 {
  background-color: #274e66;
  background: linear-gradient(#274e66 0%, #60bfb5 60%);
  border-radius: 4px;
}

body.bg-variant-turquoise footer#footer, body.bg-variant-turquoise .footer {
  background-color: #60bfb5;
  border-radius: 4px;
}

body.bg-variant-turquoise .ink {
  background-color: rgba(241, 250, 249, 0.5);
}

body.bg-variant-turquoise aside#sidebar a .card.active {
  background-color: #274e66;
  background: linear-gradient(#274e66 0%, #60bfb5 60%);
  border-color: #60bfb5;
  color: #ffffff;
}

body.bg-variant-blue {
  background-color: #325ed1;
  background-image: url("assets/image/bg_stripes.png");
  background: url("assets/image/bg_stripes.png"), linear-gradient(#325ed1 0%, #0a1033 35%);
}

body.bg-variant-blue #header-container {
  background: transparent url("assets/image/bg_header_blue.png") no-repeat center bottom;
}

body.bg-variant-blue #main-nav-container ul li.active a,
body.bg-variant-blue #main-nav-container ul li a:hover {
  /*color: $light;*/
  /*border-color: $light;*/
  color: #fff;
  background-color: #325ed1;
  padding: 2px 12px;
  margin-top: 5px;
  border-radius: 3px;
}

body.bg-variant-blue #content h1 {
  background-color: #172473;
  background: linear-gradient(#172473 0%, #325ed1 60%);
  border-radius: 4px;
}

body.bg-variant-blue footer#footer, body.bg-variant-blue .footer {
  background-color: #325ed1;
  border-radius: 4px;
}

body.bg-variant-blue .ink {
  background-color: rgba(217, 225, 246, 0.5);
}

body.bg-variant-blue aside#sidebar a .card.active {
  background-color: #172473;
  background: linear-gradient(#172473 0%, #325ed1 60%);
  border-color: #325ed1;
  color: #ffffff;
}

body.bg-variant-yellow {
  background-color: #f0c800;
  background-image: url("assets/image/bg_stripes.png");
  background: url("assets/image/bg_stripes.png"), linear-gradient(#f0c800 0%, #3a2f00 35%);
}

body.bg-variant-yellow #header-container {
  background: transparent url("assets/image/bg_header_yellow.png") no-repeat center bottom;
}

body.bg-variant-yellow #main-nav-container ul li.active a,
body.bg-variant-yellow #main-nav-container ul li a:hover {
  /*color: $light;*/
  /*border-color: $light;*/
  color: #fff;
  background-color: #f0c800;
  padding: 2px 12px;
  margin-top: 5px;
  border-radius: 3px;
}

body.bg-variant-yellow #content h1 {
  background-color: #876d00;
  background: linear-gradient(#876d00 0%, #f0c800 60%);
  border-radius: 4px;
}

body.bg-variant-yellow footer#footer, body.bg-variant-yellow .footer {
  background-color: #f0c800;
  border-radius: 4px;
}

body.bg-variant-yellow .ink {
  background-color: rgba(255, 244, 189, 0.5);
}

body.bg-variant-yellow aside#sidebar a .card.active {
  background-color: #876d00;
  background: linear-gradient(#876d00 0%, #f0c800 60%);
  border-color: #f0c800;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  #header-container header#header {
    height: 92px;
  }
  #header-container header#header h1 {
    margin-top: 20px;
  }
  #header-container header#header nav {
    display: none;
    visibility: hidden;
  }
}

@media screen and (min-width: 991px) {
  aside#sidebar a {
    /*padding: 0;*/
  }
}

@media screen and (max-width: 991px) {
  header#header .slogan {
    width: 455px;
    height: 25px;
    margin: 0 !important;
  }
  #main-nav-container div.navbar-collapse {
    height: auto !important;
  }
  .navbar-header {
    float: none;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-text {
    float: none;
    margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in {
    display: block !important;
  }
  .collapsing {
    overflow: hidden !important;
  }
}

@media screen and (max-width: 1200px) {
  #main-nav-container li a {
    margin-left: 0.1em !important;
    margin-right: 0.1em !important;
  }
}
