// For the background gradients
@mixin gradient($light, $dark, $percent: 35%, $stripes: false) {
  background-color: $light;

	@if $stripes {
		background-image: url('assets/image/bg_stripes.png');
		background: url('assets/image/bg_stripes.png'), linear-gradient($light 0%, $dark $percent);
	} @else {
		background: linear-gradient($light 0%, $dark $percent);
	}
}

@mixin animated($speed: 2s) {
	animation-duration: $speed;
	animation-fill-mode: both;
}

@mixin transit($speed: 0.5s, $ease: ease-out, $ctx: all) {
	transition: $ctx $speed $ease;
}
